.home-hero-header {
	position: relative;
	padding: 1rem 0;
	background-color: #000;
	color: #fff;
	@include media-breakpoint-up(md) {
		padding: 3rem 0;
		// padding: calc-rem(76px, 16px) 0;
	}

	&--image {
		position: absolute;
		width: 60%;
		height: 100%;
		right: 0;
		top: 0;

		&:before {
			content : "";  
			height: 100%;
			width:100%;
			background-color: rgba(0,0,0,0.2);
			display: block; 
		}

		&:after {
			content: "";
			background: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0) );
			width: 50%;
			height: 100%;
			display: block;
			top:0 ;
			position: absolute;
		}
	}
	
}

.hero-matrix {
	display: flex;
	flex-wrap: wrap;
	&--item {
		color: $white;
		text-decoration: none;
		flex: 1 0 50%;
		// padding: 20px;
		padding: 15px 3px;
		border: 1px solid $white;
		text-align:center;
		word-break: break-word;
		@include media-breakpoint-up(md) {
			padding: 30px 1px;
		}
		@include media-breakpoint-up(lg) {
			padding: 15px;
		}

		@include media-breakpoint-up(xl) {
			padding: 30px;
		}

		&:hover {
			text-decoration: none;
		}

		&__content {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			a {
				color: $white;
				text-decoration: none;
				word-break: break-word;
				font-size: 1rem;
				@include media-breakpoint-up(md) {
					font-size: calc-rem(20px, 16px);
					
				}
				
				@include media-breakpoint-up(xl) {
					font-size: calc-rem(23px, 16px);
				}
			}
		}

		&:hover {
			cursor: pointer;
			background-color:#fff;
			color: #000;


			a {
				color: $black;
			}
		}
	 
	}
}

.home-body-content {
	
	&--heading {
		font-size: calc-rem(36px, 16px);
		line-height: calc-rem(44px, 16px);
		font-family: $font-family-source;
		font-weight: bold;
	}
}

.home-body-listing {
	background-color: $black;
	color: $white;

	a {
		text-decoration: none;
	}

	&--heading {
		color: $white;
		font-size: calc-rem(36px,16px);
		font-weight: bold;
	}

	&--item {

		border: 1px solid $white;
		padding: calc-rem(30px, 16px);
		
		&:hover {
			background-color: $white;

			.home-body-listing--item__heading,
			.home-body-listing--item__meta,
			.home-body-listing--item__excerpt {
				color: $black;
				text-decoration: none;
			}

			.btn-ghost {
				background-color: transparent;
				color: $black;
			}
		}

		&__heading {
			color: $white;
			min-height: 6ch;
			// max-height: 6ch;
		}

		&__meta {
			color: $white;
			font-size: 1rem;
		}

		&__excerpt {
			color: $white;
			line-height: calc-rem(32px, 16px);
		}
	}

	.btn-ghost {
		background-color: transparent;
		font-size: calc-rem(14px, 16px);

	}

}


.getintouch {
	background-color: $black;
	color: $white;

	&--heading {
		color: $white;
		font-size: calc-rem(36px, 16px);
		line-height: calc-rem(42px, 16px);
	}
}