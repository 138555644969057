
.site-header {
  background-color: #000;

  .m-0 { 
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    
    color: #fff;
    font-size: 16px;

    .fa { 
      font-size: 16px;
      // margin-right: 10px;
    }
  }

  .brand {
    // margin: 6px 0;
    
    @include media-breakpoint-up(lg) {
      margin: unset;
    }
  }
}

.brand {
  display: block;
  max-width: 100%;

  &--logo {
    background-position: left;
    height: 100px;
    width: 100%;
  }
}

.news-header {
  background-color: $black;
  color: $white; 
  
  @extend .py-lg-5;

  h1 {
    color: $white;
  }

  .filter-wrapper { 
    font-weight: bold;
    font-size: calc-rem(14px, 16px);
    text-transform: uppercase;
  }

  & + .blog--wrapper {
    background-color: $black;
    color: $white;

    a {
      $color: $white;
      text-decoration: none;

      &:hover {

        article {
          background-color: $white;
          cursor: pointer;

          time,
          .entry-summary,
          .entry-title,
          .btn-ghost {
            color: $black;
            cursor: pointer;
          }
        }
      }
    }

    article {
      border: 1px solid $white;
      padding: calc-rem(30px,16px);
      position: relative;

      time {
        font-size: calc-rem(16px, 16px);
      }

      .btn-ghost {
        background-color: transparent;
      }
    }
    .entry-title {
      color: $white;
      font-size: calc-rem(28px, 16px);
      line-height: calc-rem( 32px, 16px);
      text-transform: uppercase;
    }
    .entry-summary,
    time {
      color: $white;
    }

    .read-more {
      
      @extend .btn;
      @extend .btn-ghost;
      @extend .pl-0;
      position: absolute;
      left: calc-rem(30px, 16px); 
      bottom: calc-rem(30px, 16px);
    }
  }
}
.news-pagination {
  background-color: $black;
  color: $white;

  a {
    color: rgba(221,221,221, 0.5);
  }

  .prev,
  .next {
  
      padding: 7px 14px;
      border: 1px solid;
      border-radius: 50%;
      font-size: calc-rem(30px, 16px);
      line-height: calc-rem(32px, 16px);
      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  .pages {
    line-height: calc-rem(32px, 16px);
    .current {
      padding: 12px 18px;
      // border: 1px solid;
      background-color: $white;
      color: $black;
      border-radius: 50%;
    }
    .pages-item {
      margin: 0 19px !important; 
    }
  }
}