/**
 * Footer base styles
 * @see footer.twig
 */
// .site-footer {
//     &-top {}
//     &-bottom {}
// }


.nav-footer {

	.nav-main-item {
		padding: 10px 20px;
		@include media-breakpoint-up(md) {
			padding: 25px 0;
		}
	}
	.nav-main-link {
		line-height: calc-rem(18px, 16px);
		font-size: calc-rem(16px, 16px);

		&:hover { 
			font-weight: bold;
			cursor: pointer;
			text-decoration: none; 
		}

		@include media-breakpoint-up(lg) {
			font-weight: bold;
			letter-spacing: 3px;
			font-size: calc-rem(14px, 16px);
			text-transform: uppercase;

			&:hover {
				color: #333;
			}
		}
	}
}
.footer-top {
	border-bottom: 1px solid rgba(0,0,0,0.1);

	@include media-breakpoint-up(lg) {
		border-bottom: 0px;
	}

	& > .container {
		@include media-breakpoint-up(lg){
			border-bottom: 1px solid rgba(0,0,0,0.1);
		}
	}
}

.footer-logo-wrapper {

	& > a {
		display: inline-block;
	}

	.brand {
		width: 70%;
		background-position: left;

	}
	.partner_site_link {
		width: 20%;
	}
}

.footer-middle {
	font-size: calc-rem(14px, 16px);
	text-transform: uppercase;
	font-weight: bold;
	line-height: calc-rem(24px, 16px);
	
	border-bottom: 1px solid rgba(0,0,0,0.1);

	@include media-breakpoint-up(lg) {
		border-bottom: 0px;
		
		& > .container {
			border-bottom: 1px solid rgba(0,0,0,0.1);
		}
	}

	.email,
	.phone {
		display: block;
	}

	.footer-heading {
		font-size: calc-rem(11px, 16px);
		letter-spacing: 1px;
		line-height: calc-rem(20px, 16px);
	}
}

.footer-bottom {
	font-size: calc-rem(11px, 16px);
	color: #979EA6;
	line-height: calc-rem(24px, 16px);

	a,
	p { 
		font-size: calc-rem(11px, 16px);
		color: #979EA6;
		line-height: calc-rem(24px, 16px);
		margin-bottom: 0;
		@include media-breakpoint-up(md) {
			margin-bottom: 1rem;
		}
	}
}