.block.page-builder {
  padding: $page-builder-block-spacing 0;
}

/*
 * Banner Images
 * @see pb-banner-image-block-layout.twig
 */
.page-builder-banner--block {
  .pb--banner {
    display: block;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      width: 100%;
      transition: padding-bottom 0.5s ease;

      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%;
      }
    }
  }

  &.standard {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 60%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }

  &.full-width {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 65%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }
}

/*
 * Slider
 * @see pb-page-slider-block-layout.twig
*/
.pb--slider {
  // Styling for middle arrows
  &.middle-arrows {
    .owl-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      z-index: 10;
      padding: 0 10px;

      .owl-prev,
      .owl-next {
        &::before {
          font-family: FontAwesome;
          color: #fff;
          font-size: 1rem;
          line-height: 1;
        }

        span {
          display: none;
        }
      }

      .owl-prev::before {
        content: "\f060";
      }

      .owl-next::before {
        content: "\f061";
      }
    }
  }

  &.sc-dots-styling {
    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      .owl-dot {
        span {
          background: map-get($theme-colors, secondary);
        }

        &.active {
          span {
            background: map-get($theme-colors, primary);
          }
        }
      }
    }
  }
}

.pb--slide {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
}

/*
 * Featherlight Styles
*/
.with-featherlight {
  .featherlight .featherlight-content {
    border-bottom: none;
    padding: 0;
  }
}
