.block {}

.topbar {
	background-color: #444444;
	color: $white;
	margin-bottom: -1px;
	span {

		font-size: 1rem;
	}

	a {
		color: $white;

		&:hover {
			color: #ccc;
			text-decoration: none;
		}
	}
}

.home-body-content--wrapper,
.single-wrapper {
	padding-top: 3rem;
	padding-bottom: 3rem;
	@include media-breakpoint-up(lg) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.feature-page-header {

	.category-small {
		font-size: calc-rem(16px, 16px);
		font-weight: bold;
		letter-spacing: 1px;
		text-transform: uppercase;
		line-height: calc-rem(20px, 16px);
	}
	
	.single-post-heading {
		font-size: calc-rem(36px, 16px);
		line-height: 42px;
	}
	
	.meta {
		display: block;
		font-size: calc-rem(18px, 16px);

		i {
			font-size: 1rem;
		}
	}

}

.contact {
	
	.small-heading {
		font-size: calc-rem(16px, 16px);
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: bold;
		line-height: calc-rem(20px, 16px);
	}

	.contact-grid {
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
		
		li {
			display: inline-block;
			padding-left: 2em;

			@include media-breakpoint-up(md) {
				width: 50%;
				display: inline-block;
			}
		}

		.address,
		.email a,
		.phone a {
			font-size: calc-rem(20px, 16px);
			line-height: calc-rem(28px, 16px);
			position: relative;
		}

		.email,
		.phone {
			position: relative;
		}

		.address {

			&:after {
				content: "\f3c5";
				
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				
				font-family: "Font Awesome 5 Pro";
				font-weight: 900;

				position: absolute;
				top: 0;
				left: 0;

			}
		}
		.phone {

			&:after {
				content: "\f095";
				
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				
				font-family: "Font Awesome 5 Pro";
				font-weight: 900;

				position: absolute;
				top: 0;
				left: 0;

			}
		}
		.email {

			&:after {
				content: "\f0e0";
				
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				
				font-family: "Font Awesome 5 Pro";
				font-weight: 900;

				position: absolute;
				top: 0;
				left: 0;

			}
		}
		

	}

	.contact-page-form {
		.half-width {
			width: 100%;
		}

		.ginput_container {
			input[type=email], 
			input[type=text], 
			textarea {
				border-bottom: 1px solid #000;
			}

			textarea {
				height: calc-rem(182px, 16px) !important;
			}
		}

		.gform_footer {
			.button {
				@extend .btn;
				@extend .btn-primary;
			  
			  i {
				display: none;
			  }
			}
		  }
	}
}

#map-container {
	min-height: 50vh;
}

.staff-listing {
	background-color: $black;
	color: $white; 

	&--page-title {
		& h1 {
			color: $white;
			
		}
	}

	&--post-item {
		a,
		.entry-title {
			color: $white;
			text-decoration: none;
		}

		.entry-title {
			font-size:calc-rem(28px,16px);
			line-height: calc-rem(32px, 16px);
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 1rem;
			margin-bottom: 0;
		}
		.entry-role {
			font-size: calc-rem(20px, 16px);
			line-height: calc-rem(32px,16px)
		}
		

		&:hover {

			.staff-listing--item-image {
				transform: scale(1.035);
				transition: 100ms linear transform;
			}
		}
			
		
		.staff-listing--item-image {
			width: 100%;
			padding-bottom: 100%;
			transform: scale(1);
			transition: 100ms linear transform;
		}
		
	}
}

.single-staff {
	.single-staff-page-header {
		background-color: $black;
		color: $white;
		.single-post-heading {
			font-size: calc-rem(60px, 16px);
			color: $white;
			line-height: calc-rem(66px, 16px);
		}
	}
	.social {
		
		display: flex;

		.social-item {
			a {
				color: $white;
				font-size: calc-rem(21px, 16px);
				
				&:hover {
					color: #ccc;
				}
			}
		}
	}

	.single-header-image {
		padding-bottom: 79%;
	}
	.staff-single-content {
		background-color: $black;
		color: $white;

		p,a,b,i,span,div {
			color: $white;
		}
	}
}


.testimonial-slider {
	background-color: $black;
	color: $white;
	a {
		color: $white;
		text-decoration: none;
	}

	.slider-wrapper--outer {
		> .container {
			position: relative;
		}
		.arrow-next,
		.arrow-prev {
			display: block;
			padding: 5px 17px;
			border: 1px solid rgba(255,255,255,0.2);
			border-radius: 50%;
		}
		.arrow-next{
			position: absolute;
			top: 50%;
			right: -40px;
		}
		.arrow-prev{
			position: absolute;
			top: 50%;
			left: -40px;


		}
	}
	
	&--item {
		
		border: 1px solid $white;
		padding: 1.875rem;

		.contain-content {
			font-size: calc-rem(22px, 16px);
			font-family: $font-family-source;
			line-height: calc-rem(32px,16px);
			font-weight: bold;

		}

		.btn-ghost {
			background-color: transparent;
			cursor: pointer;
		}

		&:hover {
			background-color: $white;
			color: $black;
			cursor: pointer;
			
			.btn-ghost {
				color: inherit;
				cursor: pointer;
			}
			
			.testimonial-slider--title {
				color: inherit;
				cursor: pointer;
			}
		}
	}

	&--title {
		color: $white;
		font-size: calc-rem(20px, 16px);
		font-family: $font-family-lato;
		font-weight: 400;
	}
}


.page-header-testimonial {
	background-color: $black;
	color: $white;

	h1 {
		color: $white;
		margin: 1rem 0;
		@include media-breakpoint-up(lg) {
			margin: 3rem 0;

		}
	}
}