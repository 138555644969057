.feature-page-header {
	 background-color: $black;
	 color: $white;
	 position: relative;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 min-height: 40vh;
	 @include media-breakpoint-up(lg){
		 min-height: 60vh;
	 }


	 h1 {
		 color: $white;
		 font-size: 3rem;
	 }

	 &--background {
		 position: absolute;
		 top: 0;
		 bottom: 0;
		 right: 0;
		 width: 100%;
		 opacity: 0.7;
		 @include media-breakpoint-up(lg){
			 width: 50%;
			 opacity: 1;
		 }
	 }
}

.feature-page-content {
	padding-top: 3rem;
	padding-bottom: 3rem;

	@include media-breakpoint-up(lg){
		padding-top: 5rem;
		padding-bottom: 5rem;
		
	}


	p {
		cursor: default;
	}

	a {
		color: #666;
		
		&:hover {
			text-decoration: underline;
		}
		&.btn{
			text-decoration: none;
		}
		&.btn-primary {
			&:hover {
				background-color: $white;
				color: $black;
				border: 1px solid rgba(0,0,0,0.5);
			}
		}

	}
	
}