.button-styles {
  font-family: $font-family-source;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 20px;
  margin: .25rem 0;
  text-transform: uppercase;
}

.btn {
  @extend .button-styles;
  color: map-get(map-get($button-colors, standard), base-color);
  background-color: map-get(
    map-get($button-colors, standard),
    base-background);
    
    &:hover {
      color: map-get(map-get($button-colors, standard), hover-color);
      background-color: map-get(
        map-get($button-colors, standard),
        hover-background
        );
      }
      
  &.btn-primary {
    @extend .button-styles;
    color: map-get(map-get($button-colors, primary), base-color);
    border: 1px solid rgba(0,0,0,0.5); 
    background-color: map-get(
      map-get($button-colors, primary),
      base-background
      );
      
      &:hover {
        color: map-get(map-get($button-colors, primary), hover-color);
        background-color: map-get(
          map-get($button-colors, primary),
          hover-background
          );
        }

    }
      
  &.btn-secondary {
    @extend .button-styles;
    color: map-get(map-get($button-colors, secondary), base-color);
    background-color: map-get(
      map-get($button-colors, secondary),
      base-background
    );

    &:hover {
      color: map-get(map-get($button-colors, secondary), hover-color);
      background-color: map-get(
        map-get($button-colors, secondary),
        hover-background
      );
    }
  }
}
