/**
 * KeyFrames for the navigation
 */ 

/**
 * Navigation Styles
 */
.navigation-ul {
  padding: 0;
}

.nav-main-link {
  color: $navigation-link-color;
}

// Header / Primary Navigation
.nav-primary {

  .nav-drop-link {
    text-align: left;

    &:hover {
      color: $black;
      position: relative;


      &:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 2px;
        background-color: $black;
        left: -85px;
        top: 50%;
      }
    }
  }
  .nav-primary-ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
  .menu-item-has-children{

    .nav-main-link {
      padding-right: 40px;
      position: relative;

      &:after {
        content: "\f078";
        
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        
        right: 17px;
        position: absolute;
        font-size: 12px;
        top: 34%;
      }

    }

  }
  .nav-main {

    &-link {
      // padding: 0.75em 1.5em;
      text-align: center;
      display: inline-block;
      position: relative;
      padding: 16px 20px;

      
      
    }

    &-item {
      &.menu-btn{
        &:hover {

            &:after {
              display:none;
            }
          
        }
      }
      &:hover {

        .nav-main-link {
          color: $white;
          font-weight: bold;

          
        }

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $white;
        }
      }

      &:last-of-type {
        .nav-drop {
          right: 0;
          left: initial;
        }
      }

      &:hover {
        & > .nav-drop {
          border: 1px solid rgba(0,0,0,0.03);
          transform: translate(0, 100%);
          opacity: 1;
          z-index: 999999;
          pointer-events: all;
          visibility: visible;
          height: auto;
          overflow: initial;
        }
      }

    }

  }

  .nav-main-item, 
  .nav-drop-item {
    position: relative;
  }
  
  // Dropdown
  .nav-drop {
    display: block;
    height: 0;
    overflow: hidden;
    position: absolute;
    bottom: 2px;
    left: 0;
    transform: translate(200px, 100%);
    background: $white;
    color: rgba(0, 0, 0, 0.7);
    width: 200px;
    // transition: transform 0.45s ease-in, opacity 0.45s linear;
    opacity: 0;
    z-index: -999999;
    pointer-events: none;
    visibility: hidden;
    
    &-link {
      font-size: calc-rem(16px, 16px);
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
      padding: 0.75em 1.5em;
      // @extend .nav-main-link;
    display: block;
    }
  }
}

// Footer / Seconary Navigation
.nav-footer {

  &-ul {}

  // Regular
  .nav-main {
    &-item {}
    &-link {}
  }

  // Dropdown
  .nav-drop {
    &-item {}
    &-link {}
  }
  
}

// Mobile Menu Styles
.mobile-nav-primary {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  // bottom: 0;
  // height: 100%;
  //background-color: map-get(map-get($rsv-theme-colors, gray), base);
  // z-index: -9999;
  // display: none;
  // opacity: 0;
  @include animation(0, 0.3s, ease-out, hideNav);

  @include media-breakpoint-up(lg) {
    display: none !important;
    z-index: -9999999;
    pointer-events: none;
  }

  & > .container-fluid {
    background-color: #000;
  }

  .mobile-nav-logo {
    &--wrapper {
      background-color: #000;
    }
  }
}
.nav-mobile-primary-close--wrapper {
  color: $white;
  span {
    font-size: 16px;
  }
}
.nav-mobile-primary-close--wrapper, .header--menu {
  cursor: pointer;

}


.mobile-menu--wrapper{
  flex-direction: column;
  padding: 23px;
  border-bottom: 1px solid black;
}

// @see navigation.twig -> should be the ul
#menu-mobile-menu {
  @extend .pl-0;
  
  .nav-main-item {
    @extend .no-dot !optional;
    @extend .pl-3;
    margin-bottom: 1rem;

    &.menu-item-has-children {
      .nav-main-link {

        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 100%;
          margin-left: 0.8em;
          top: 50%;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid rgba(0,0,0,0.25);
          
        }
      }
    }

    &.opened {
      .nav-drop {
        display: block;
        max-height: 100%;
        animation: 250ms linear max-height;
      }
    }

    .nav-drop {
      display: none;
      max-height: 1px;
      @extend .pl-3;

      .nav-drop-link {
        color: rgba(0,0,0,0.5);
        &:hover {
          color: #000;
          text-decoration: none;
        }
      }
    }

    .current-item {
      .nav-main-link {
        color: #111;
      }
    }
    .nav-menu-item {
      &.opened {
        .nav-main-link{
          &:before {
            display: block;
          }
        }
      }

      
    }
    .nav-main-link {
      position: relative;
      color: rgba(0,0,0,0.5);
      display: inline-block;
      font-size: 18px;
      line-height: 24px;

      

      &:before {
        content: "";
        display: none;
        width: 35px;
        height: 3px;
        background-color: #000;
        position: absolute;
        left: -50px;
        top: 50%;
      }
      
      &:hover {
        text-decoration: none;
        color: #000;

        &:before {
          display: block;
        }

        &:after {
          border-top: 5px solid #000;
        }
      }

    }
  }
}

html {
  position: relative;
  -webkit-overflow-scrolling: touch;
  min-width: 100%;
  height: auto;
  overflow-y: scroll !important;

  body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    min-width: 100%;
    height: auto;
    overflow-y: scroll !important;
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 10000px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    z-index: -9999;

    @include animation(0, 0.15s, linear, overlayHide);
  }

  & .site--wrapper {
    @include animation(0, 0.3s, linear, slideClosed);

    position: relative;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  &.loading {
    &::after {
      @include animation(0, 0, none, none);
    }

    & .site--wrapper {
      @include animation(0, 0, none, none);
    }

    & .mobile-nav-primary {
      @include animation(0, 0, none, none);
    }
  }

  &.nav-open {
    &::after {
      // @include animation(0.3s, 0.3s, linear, overlayFade);
    }

    & .site--wrapper {
      // @include animation(0, 0.3s, ease-out, slideOpen);
      z-index: -1;
    }

    .mobile-nav-primary {
      // @include animation(0.3s, 0.3s, ease-out, showNav);
      left: 0%;
      display: block;
    }
  }
}

.nav-primary-close--wrapper {
  color: #fff;

  i {
    margin-right: 5px;
  }
}

//.nav-primary {
//  .nav-main-item {}
//  .nav-link-item {}
//  .nav-drop {}

//  .nav-drop-link {}
//}

.nav-primary {

  .current_page_item {
    & > a {
      font-weight: bold;
      color: $white;
    }
  }
  .nav-main-link {
    color: rgba(255,255,255,0.5);
    font-size: calc-rem(16px, 16px);
    line-height: calc-rem(24px, 16px);
    font-family: $font-family-lato;
    text-decoration: none;
  }
  .nav-drop-item {
    overflow: hidden;
  }
  & .menu-btn {
    margin-left: 1rem;
    &.btn-secondary {
      & > a {
        @extend .btn;
        @extend .btn-secondary;
        margin: 0;
      }
    }
  }
}


.mobile-nav-contact {
  .meta {
    font-size: 18px;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.mobile-nav-social {
  &--wrapper {
    .social-title {
      font-size: 18px;
    }
    .social-media {
      padding-left: 0.5em;

      &--item {
        padding: 5px;
      }
    }
  }
}









