.image-square {
  position: relative;
  //background-color: #eee;
  &::before {
    content: '';
    width: 100%;
    display: block;
    padding-bottom: 100%;
  }
}

.image-rectangle {
  position: relative;
  width: 100%;
  //background-color: #eee;

  &::before {
    display: block;
    content: '';
    position: relative;
    padding-bottom: 75%;
  }
}
