// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/*
 *  Gravity Forms Example Form Styles
 */

// #gform_wrapper_1 {
//   form#gform_1 {
//     .gform_body {
//       // Form List Itmes
//       li.gfield {
//       }
//       // Form List Item Labels
//       label.gfield_label {
//       }
//     }

//     .gform_footer {
//       // Submit Button
//       .button {
//       }
//     }
//   }
// }

.gform_footer {

  .gform_button {
    font-size: 1rem !important;
    cursor:  pointer !important;
  }
  input[type="submit"] {
    cursor: pointer;
  }
}
