.single {

}

.single-hero-header {
	background-color: #000;
	color: #fff;
	@include media-breakpoint-up(lg) {
		padding: calc-rem(76px, 16px) 0;
	}

	h1 {
		color: $white;
	}
}
