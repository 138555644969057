.staff-slider {
    background-color: $black;
    color: $white;
    position: relative;
    
    .slick-arrow {
        i {
            font-size: calc-rem(30px, 16px);
        }
    }

    .prev-control {
        position: absolute;
        left: 5%;
        font-size: calc-rem(24px,16px);
        top: 35%;
        @include media-breakpoint-up(lg){
            top: 25%;
        }

    }
    .next-control {
        position: absolute;
        right: 5%;
        font-size: calc-rem(24px,16px);
        top: 35%;
        @include media-breakpoint-up(lg){
            top: 25%;
        }
    }
   &--item {
    //    border-top: 1px solid #ccc;
    //    border-right: 1px solid #ccc;
    //    border-bottom: 1px solid #ccc;
    //    display: inline-block;
    //    padding: 2rem;

    transition: 100ms linear margin-top;

    &:hover {
        transition: 100ms linear margin-top;
        margin-top: -15px;
        
        // background-color: #1C1C1C;
         cursor: pointer;
        .practice-slider--title {
            color: $white;
        }
        .practice-slider--blurb {
            color: $white;
        }
        .btn-ghost{
             color: $white;
        }
    }
   }

   &--title {
       color: $white;
       font-size: calc-rem(28px, 16px);
       line-height: calc-rem(32px, 16px);
       font-weight: bold;
   }

   &--blurb {

       font-size: calc-rem(16px, 16px);
       line-height: calc-rem(24px, 16px);
       color: $white;
   }
   &--img {
       padding-bottom: 70%;
   }
   
}
.slider-wrapper {
    a {
        text-decoration: none;
    }
}