* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: -1;
}
#wpadminbar {
  position: fixed !important;
}
body {
  // box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  min-height: 100vh;
  outline: white;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

input, textarea {
  outline: none;
}

/**
 * Responsive Video Embeds 
 */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/**
 * Remove Mobile IOS Styles
 */
input, textarea, button {
  -webkit-appearance: none;
  -webkit-border-radius:0px;
}

// Fonts 
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('../fonts/lato-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v14-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lato-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Italic'), local('Lato-Italic'),
       url('../fonts/lato-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v14-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
       url('../fonts/lato-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v14-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../fonts/source-sans-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v11-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
       url('../fonts/source-sans-pro-v11-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-sans-pro-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('../fonts/source-sans-pro-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v11-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}