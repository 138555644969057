/*
 * This is the stylesheet used to style social media elements.
 * @see blocks/social-media.twig
 * The idea is you wrap the social partial in a wrapper and use that to style individual sections.
 *
 * .social-media -> ul
 * &--item -> li
 * &--link -> a
 */

.social-media {
  display: inline-flex;
  flex-flow: row nowrap;
  margin: 10px 0;
  &--item {
    margin: 0 10px;
    line-height: 1;
  }
  &--link {
    &:hover {
      text-decoration: none;
    }
  }
}

// EXAMPLES

//.header-social--wrapper {
//  .social-media {
//    &--item {}
//    &--link {}
//  }
//}

//.footer-social--wrapper {
//  .social-media {
//    &--item {}
//    &--link {}
//  }
//}
.footer-social--wrapper {
  .social-media {
    padding-left: 0;
    &--item {
      a {
        &:hover {
          color: #666;
        }
      }
    }
  }
}