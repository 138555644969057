.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.size-contain {
    background-size: contain;
  }
}

.no-decoration {
  &:hover {
    text-decoration: none;
  }
}

.double-column {
  column-count: 2;
}

.background-overlay-parent {
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.25);
    left: 0; 
    top: 0;
  }
}