.practice-slider {

   &--item {
		border-top: 1px solid #ccc;
		border-right: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		display: inline-block;
		padding: 2rem;
		width: 100%;

	   &:hover {
		
		background-color: #1C1C1C;
		 cursor: pointer;
		.practice-slider--title {
			color: $white;
		}
		.practice-slider--blurb {
			color: $white;
		}
		.btn-ghost{
			 color: $white;
		}
	}
   }

   &--title {
	   font-size: calc-rem(20px, 16px);
	   font-weight: bold;
	   line-height: calc-rem(28px, 16px);
	   min-height: 5ch;
   }

   &--blurb {
	   font-size: calc-rem(16px, 16px);
	   color: rgba(0,0,0,0.5);
	   line-height: calc-rem(24px, 16px);
   }

   .btn-ghost {
	   background:transparent;
	   color: #ccc;
   }


   .controls {
	   span {
		   font-size: calc-rem(24px, 16px);
		   padding: calc-rem(8px , 16px) calc-rem(30px, 16px);
		   cursor: pointer;
		}
		.prev-control {
			position: relative;
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 0; 
				height: 100%;
				width: 1px;
				background-color: #ccc;
			}

			&:hover {
				color: #666;
			}
		}
		.next-control {
			&:hover {
				color: #666;
			}
		}
   }
   
}